import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InfoIcon from '@mui/icons-material/Info';
import Constants from "../utilities/Constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import {Helmet} from "react-helmet";


const Layout = () => {

  const [lang, setLang]       = React.useState()
  const [wait, setWait]       = React.useState(true)
  const [help, setHelp]       = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [available, setAvail] = React.useState(true)
  const [head, setHead]       = React.useState(true)

  Constants.navigate = useNavigate();
  Constants.host     = '';

  const handleChange = (e) =>{
    Constants.selectedLanguage = e.target.value
    setLang(e.target.value)
    Constants.navigate('/redirect')
  }

  React.useEffect(() => {
    if (window.location.protocol == 'https:') {  

     } else {
      setWait(false)
     }

     Constants.host = window.location.host == 'localhost:3001' ? 'http://localhost:8005/v1/' : 'https://votersearch.i-vote.in/v1/'

     load()
  }, [])

  const load = async () => {
    await axios.get(`${Constants.host}details?subdomain=${window.location.host == 'localhost:3001' ? 'gayatri.i-vote.in' : window.location.host}`).then(data => {
      data = data.data.data
      // console.log(data)
      Constants.api               = {}
      Constants.api.voterList     = `constituencies/{CONSTITUENCY}/umedvar/${data.umedvar_id}/electionmeta?FN={FNAME}&MN={MNAME}&LN={LNAME}&PartNo=&voterid={EPICNO}&MobileNumber=&per_page=20&page={PAGE}&school={SCHOOL}`
      Constants.banner            = data?.meta_og_image ? `${data?.constants.document_url}/${data?.meta_og_image}` : ''
      Constants.constituencies    = data?.constituencies
      Constants.details           = data?.details
      Constants.languages         = data?.languages
      Constants.umedvar_languages = data?.umedvar_languages
      Constants.selectedLanguage  = data?.umedvar_languages.filter(f => f?.is_default === 1)[0]?.languages?.code
      Constants.searches          = data?.searches
      Constants.lables            = data?.lables
      Constants.messages          = data?.messages
      Constants.party             = data?.party
      Constants.constants         = data?.constants
      Constants.learnings         = data?.learnings
      setHead(data)
      setAvail(true)
      setLoading(false)
    }).catch(e => {
      setLoading(false)
      setAvail(false)
    })
    
  }

  const breadcrumbs = () => {
    let url = window.location.pathname;
    switch(url.split('/')[1]) {
      case '':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Typography color="text.primary" style={{ display: 'flex',
                                                              textAlign: 'center',
                                                              justifyContent: 'center',
                                                              alignItems: 'center',
                                                              fontSize: '20px'}}
                                                              className="text-brand"
                                                          >
                      <HomeIcon />
                      {Constants?.languages[Constants.selectedLanguage].layout.bc.home || 'Home'}
                    </Typography>
                </Breadcrumbs>;
      case 'search':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <HomeIcon />
                      {/* {Constants.languages[Constants.selectedLanguage].layout.bc.home} */}
                    </Link>
                    <Typography color="text.primary" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <SearchIcon />
                      {Constants?.languages[Constants.selectedLanguage].layout.bc.search || 'Search'}
                    </Typography>
                </Breadcrumbs>;
      case 'list':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <HomeIcon />
                      {/* {Constants.languages[Constants.selectedLanguage].layout.bc.home} */}
                    </Link>
                    <Link underline="hover" color="inherit" to="/search" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <SearchIcon />
                        <Typography color="text.primary" className="text-brand">
                          {/* {Constants.languages[Constants.selectedLanguage].layout.bc.search} */}
                        </Typography>
                    </Link>
                    <Typography color="text.primary" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <FormatListBulletedIcon />
                      {Constants?.languages[Constants.selectedLanguage].layout.bc.list}
                    </Typography>
                </Breadcrumbs>;
      case 'details':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <HomeIcon />
                      {/* {Constants.languages[Constants.selectedLanguage].layout.bc.home} */}
                    </Link>
                    <Link underline="hover" color="inherit" to="/search" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <SearchIcon />
                        <Typography color="text.primary" className="text-brand">
                          {/* {Constants.languages[Constants.selectedLanguage].layout.bc.search} */}
                        </Typography>
                    </Link>
                    <Link underline="hover" color="inherit" to="/list" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <FormatListBulletedIcon />
                      <Typography color="text.primary" className="text-brand">
                        {/* {Constants.languages[Constants.selectedLanguage].layout.bc.list} */}
                      </Typography>
                    </Link>
                    <Typography color="text.primary" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <InfoIcon />
                      {Constants?.languages[Constants.selectedLanguage].layout.bc.details || 'Details'}
                    </Typography>
                </Breadcrumbs>;
        default:
          return <></>
    }
                  
  }

  if(!available) {
    return <h1 style={{padding: '10px', margin: '10px'}}>Please check URL</h1>
  }

  return loading ? <CircularProgress style={{margin: "0 auto", display: "block"}}/> : (
    <>
        <Helmet>
          <title>{head.title}</title>
          
          <style type="text/css">{`
              .bg-brand {
                background-color: ${head.brand_background_color}!important;
                color: ${head.brand_color};
              }
              .bg-brand a {
                color: ${head.link_color};
              }
              .bg-brand-secondery {
                background-color: ${head.secondery_color};
                color: ${head.text_color};
              }
              .text-brand {
                color: ${head.text_color}!important;
              }
          `}</style>
        </Helmet>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" className="bg-brand">
            <Toolbar>
              <Typography 
                variant="h3"
                sx={{ textAlign: 'center' }}
              > 
                {/* <Link to={'/'}>
                    <HomeIcon />
                  </Link> */}
              </Typography>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }} display="flex" justifyContent="flex-end">
               {Constants?.languages[Constants.selectedLanguage].layout.ttl.siteTitle || 'Title'}  
              </Typography> 
              <Typography variant="h7" component="div" sx={{ flexGrow: 1 }} display="flex" justifyContent="flex-end">
                <FormControl size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Constants.selectedLanguage}
                    onChange={handleChange}
                  >
                    {Constants.umedvar_languages.map(ul => <MenuItem value={ul?.languages?.code}> {ul?.languages?.label} </MenuItem>)}
                  </Select>
                </FormControl>
              </Typography> 
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{pb:7}}>
            <div style={{display: 'flex', paddingTop: '15px'}}>
            {
              breadcrumbs() 
            }
            <Typography 
              variant="h7" 
              component="div" 
              sx={{ flexGrow: 1, textAlign: 'center', cursor: 'pointer' }} 
              display="flex" 
              justifyContent="flex-end"
              className="text-brand"
              onClick={() => setHelp(!help)}
            >
               {help ? (Constants?.languages[Constants.selectedLanguage].layout.help.close || 'Close') : (Constants?.languages[Constants.selectedLanguage].layout.help.help || 'Help')}  
              </Typography> 
            </div>
            <Divider sx={{mb: 2}} />
            <Paper sx={{mt:3}}>
                <img src={Constants?.banner} width={'100%'} alt="banner" />
            </Paper>
              { help ? <section style={{width:"100%", padding: '20px 10px', zIndex: 1}}>
                <p>
                  {Constants?.languages[Constants.selectedLanguage].layout.lbl.stepsTitle || 'Steps'}
                    :
                </p>
                <ol>
                    <li>
                      {Constants?.languages[Constants.selectedLanguage].layout.lbl.step0 || 'Step 1'}
                    </li>
                    <li>
                      {Constants?.languages[Constants.selectedLanguage].layout.lbl.step1 || 'Step 2'}
                    </li>
                    <li>
                      {Constants?.languages[Constants.selectedLanguage].layout.lbl.step2 || 'Step 3'} 
                    </li>
                    <li>
                      {Constants?.languages[Constants.selectedLanguage].layout.lbl.step3 || 'Step 4'}
                    </li>
                    <li>
                      {Constants?.languages[Constants.selectedLanguage].layout.lbl.step4 || 'Step 5'}
                    </li>
                    <li>
                      {Constants?.languages[Constants.selectedLanguage].layout.lbl.step5 || 'Step 6'}
                    </li>
                </ol>
            </section> : <Outlet />}
            </Container>
            <footer style={{ width:"100%", padding: '20px 0', zIndex: 1}} className="bg-brand">
              <center>
                {/* <Link to={'learn-voting'}>
                  Dummy Voting Stimulation
                </Link> */}
                {/* Powered by <a href="https://www.i-vote.in/" target="_blank" rel="noreferrer">I-Vote</a> */}
              </center>
            </footer>
            
        </Box>
    </>
  )
};

export default Layout;