import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Constants from './../utilities/Constants';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const Home = () => {

  
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen]       = React.useState(false);
    const [constituencies, setConstituencies] = React.useState();

    const selectConstituency = element => {
        if(Constants?.selectedConstituency?.number !== element?.number) {
            Constants.search = {}    
        }
        if(element.token === '') {
            setOpen(true);
            setTimeout(() => setOpen(false), 1000)
        } else {
            Constants.selectedConstituency = element
            Constants.navigate('/search')
        }
    }

    React.useEffect(() => {
        setConstituencies(Constants?.constituencies)
        if(Constants?.constituencies?.length === 1) {
            selectConstituency(Constants?.constituencies[0])
        }
        setLoading(false)
    }, [])

    return (
        <>
            <Card >
            
                <CardHeader title={
                    Constants.languages[Constants.selectedLanguage].home.ttl.pageTitle
                } sx={{ textAlign: 'center' }} className='bg-brand' />

                <CardContent>

                { loading ? [1,2,3,4,5,6,7].map(e => <>
                            <Skeleton variant="rectangular" width={'100%'} height={100} animation="wave" />
                            <Divider sx={{margin: '10px 0'}} />
                        </>) :
                    <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                        {/* <Divider sx={{margin: '10px 0'}} /> */}
                        {constituencies?.map(e => (
                            <>
                                <ListItem key={e.constituency_id} component="div" disablePadding
                                    style={e.token === '' ? {color: 'gray'} : {}}
                                >
                                    <ListItemButton onClick={() => selectConstituency(e)}>
                                        <ListItemText primary={`${Constants.selectedLanguage == 'en' ? e.constituencies.name : e.constituencies.name}`} sx={{ textAlign: 'center' }} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider sx={{margin: '10px 0'}} className='bg-brand' />
                        </>
                        ))}
                    </List>
                }
                </CardContent>

            </Card>
            <Snackbar
                open={open}
            >
                <Alert severity="error">Coming soon!</Alert>
            </Snackbar>
        </>
            
    )
}

export default Home;
