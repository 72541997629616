import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Constants from './Constants';
import CardHeader from '@mui/material/CardHeader';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';

const ICard = ({e}) => {


    const [columns, setColumns] = React.useState([])
    const [title, setTitle] = React.useState('')

    React.useEffect(() => {
        load()
    }, [])

    const load = () => {
        let headers = Constants?.lables.filter(l => l.constituency_id == Constants?.selectedConstituency?.constituency_id)
                                       .filter(l => l.language_code == Constants.selectedLanguage)
                                       .filter(l => l.is_detail === 1)
        let titles  = headers.filter(l => l.is_title === 1)
        setColumns(headers.filter(l => l.is_title === 0))

        setTitle(titles.map(t => e[t.col]).join(' | '))
        // let td      = 
    }

    return (<>
        <ListItem alignItems="flex-start" key={e.m_id}>
                <Link to={`/details/${e.m_id}`} style={{ textDecoration: 'none' }}>
                    <ListItemText
                    primary={
                        <>
                            <h2 className='text-brand' style={{paddingBottom: 0, marginBottom: 0, textAlign: 'center'}}>
                                {title}                                    
                            </h2>
                        </>
                    }
                    secondary={
                        <>
                        <Grid container spacing={2} sx={{width: '100%'}}>
                            {
                                columns?.map(c => (<Grid item xs={12}>
                                    {
                                        c.label == ',' ? <Typography sx={{ fontWeight: c.is_bold ? 'bold' : 'normal' }}>{e[c.col]}</Typography> 
                                                        : <Typography sx={{ fontWeight: c.is_bold ? 'bold' : 'normal' }}>
                                                            {c.label} : {e[c.col]}
                                                          </Typography>
                                    }
                                </Grid>))
                            }
                        </Grid>
                        </>
                    }
                    />
                </Link>
            </ListItem>
    </>
                    
)}

export default ICard;
